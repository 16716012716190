/*
 * Color scheme is based on Kanagawa
 * Source: https://github.com/rebelot/kanagawa.nvim
 */

.App {
    background-color: #1F1F28;
    font-size: 17px;
    color: #DCD7BA;
    font-family: 'JetBrains Mono', monospace;
    cursor: text;
}

.terminal-container {
    position: fixed;
    background-color: #1F1F28;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.text-field {
    width: calc(100% - 250px);
    border: none;
    background-color: #1F1F28;
    font-size: 17px;
    font-weight: bold;
    color: #76946A;
    overflow: auto;
    outline: none;
    font-family: 'JetBrains Mono', serif;
}

.wave {
    animation-name: waving;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
    font-size: 20px;
}

@keyframes waving {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }
    100% { transform: rotate( 0.0deg) }
}

::selection {
    background: #76946A;
    color: #1F1F28;
}

a, a:link, a:visited, a:hover, a:active {
    color: #76946A;
}

.cmd {
    color: #76946A;
    text-decoration: underline;
    font-weight: bolder;
    margin: 0px;
}
